<template>
    <div class="home">
        <router-link to="/patients" class="is-pulled-right"><button class="button is-info is-small">Back to Patient List </button></router-link><br /><br />
          <div class="box">	
            <form @submit.prevent="savePatient" v-if="loading===false">
                <div class="field">
                    <label class="label">Patient ID </label>
                    <div class="control">
                        <input class="input" type="text" v-model="patient_id"  >
                    </div>
                </div>

                <div class="field">
                    <label class="label">Forename</label>
                    <div class="control">
                        <input class="input" type="text" v-model="forename" required  >
                    </div>
                </div>

                <div class="field">
                    <label class="label">Surname</label>
                    <div class="control">
                        <input class="input" type="text" v-model="surname" required >
                    </div>
                </div>

                <div class="field">
                    <label class="label">Postcode</label>
                    <div class="control">
                        <input class="input" type="text" v-model="postcode"  >
                    </div>
                </div>
      
                <div class="field">
                    <label class="label">Date of Birth</label>
                    <div class="control">
                        <input class="input" type="date" v-model="dob"  >
                    </div>
                </div>
      
                
                <div class="field">
                    <label class="label">Project</label>
                    <div class="control">
                        <select class="input" v-model="project_id"  required>
                        <option value="">Please select ...</option>
                        <option :value="row.id" v-for="row in projects" :key="row.id">{{ row.name }}</option>
                        
                    </select>
                        
                    </div>
                </div>


                    <div class="field is-grouped">
                        <div class="control">
                            <button class="button is-success" :disabled="working===true">Save Patient</button>
                        </div>
                    </div>
                </form>
            
          </div>
          
      </div>
  </template>

<script>
   import BookingService from '@/services/BookingService.js';
    import UtilityService from '@/services/UtilityService.js';
    
    import MemberMixin from '@/mixins/MemberMixin.js';

    export default {
      name: 'AddAlert',
        mixins: [MemberMixin],
        data: function () {
            return {
                   loading : false,
                   working: false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    forename: '',
                    surname: '',
                    patient_id: 'PT-',
                    dob: '',
                    postcode: '',
                    projects: [],
                    project_id: ''
                   
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
                this.getProjects();
            });
          
        },
        methods: {
            async getProjects() {
                UtilityService.getSimpleApiData(this.accessToken, "projects").then(
                    response => this.projects = response 
                );
                
            },
            async savePatient() {
                this.working = true;

                var formdata = { 
                    forename : this.forename,
                    surname: this.surname,
                    patient_id: this.patient_id,
                    dob: this.dob,
                    postcode: this.postcode,
                    project_id: this.project_id,
                   
                }; 

                BookingService.savePatient(this.accessToken, formdata)
                .then((response) => {
                    this.working = false
                    this.$router.push("/patients");
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
           
        }
    }
    </script>