<template>
    <div class="home">
        <div class="box">	
            <div class="notification is-success" v-if="saved ==true">
                <p class="mb-2">The booking has been saved successfully.</p>
                <p @click="saved=false" class="mb-2" style="cursor:pointer">Click here to add another booking starting with the same details</p>
                <p @click="clearForm()" class="mb-2" style="cursor:pointer">Click here to add another booking and clear the form </p>
            </div>
            <form @submit.prevent="saveBooking" v-if="saved == false">
                
                
                
                <div class="field">
                          <label class="label">Client</label>
                          <div class="control">
                              <select class="input" v-model="client_id"  required @change="getGradesForClient()">
                                <option value="">Please select ...</option>
                                <option :value="row.id" v-for="row in clients" :key="row.id">{{ row.name }}</option>
                               
                            </select>
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Ward</label>
                          <div class="control">
                              <select class="input" v-model="ward_id" >
                                <option value="">Please select ...</option>
                                <option :value="row.id" v-for="row in wards" :key="row.id">{{ row.name }}</option>
                                
                            </select>
                             
                          </div>
                      </div>

                      <div class="field">
                            <label class="label">Grade</label>
                            <div class="control">
                                <select class="input" v-model="grade_id"  required>
                                <option value="">Please select ...</option>
                                <option :value="row.id" v-for="row in grades" :key="row.id">{{ row.name }}</option>
                                
                            </select>
                                
                            </div>
                        </div>

                      
                      
                      <div class="field">
                          <label class="label">Break Time (mins)</label>
                          <div class="control">
                              <input class="input" type="number" v-model="break_time"  >
                          </div>
                      </div> 

                      <div class="field" v-if="client_id == 1614 ">
                          <label class="label">Is This a Sleeping Nurse Shift ?</label>
                          <div class="control">
                            <select class="input" v-model="sleeping_nurse" required>
                                <option value="">Please select ...</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                                
                            </select>
                          </div>
                      </div>

                      <hr />
                      <h2 class="title is-size-4">Dates</h2>
                      <table class="table is-fullwidth is-striped">
                        <thead>
                            <tr>
                                
                                <th>Start Date</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Reference</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, index) in dates" :key="index">
                                
                                <td><input class="input" type="date" v-model="row.start_date" required/></td>
                                <td><input class="input" type="time" v-model="row.start_time" required/></td>
                                <td><input class="input" type="time" v-model="row.end_time" required/></td>
                                <td><input class="input" type="text" v-model="row.reference" /></td>
                            </tr>
                        </tbody>
                      </table>
                      <button type="button" @click="addItem" class="button is-warning ">Add Row</button>
                    <hr />
                      <div class="field  is-fullwidth">
                          <div class="control is-fullwidth">
                              <div class="is-pulled-left"><button class="button is-success" :disabled="working===true">Save Block Booking</button></div>
                              <div class="is-pulled-right"><button class="button is-danger " type="button" :disabled="working===true" @click="clearForm">Clear Form</button></div>
                          </div>
                      </div>
                      <p style="clear:both"></p>
                  </form>
            
          </div>
          
      </div>
  </template>

<script>
    import MemberMixin from '@/mixins/MemberMixin.js';
    import BookingService from '@/services/BookingService.js';
    import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'AddStone',
        mixins: [MemberMixin],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    reference: '',
                    client_id: '',
                    ward_id: '',
                    clients: [],
                    wards: [],
                    grades: [],
                    grade_id: '',
                    start_date: this.getStartDate(),
                    start_time: '',
                    end_date: this.getEndDate(),
                    end_time: '',
                    break_time: 60,
                    working:false,
                    saved: false,
                    dates : [{'reference' : '', 'start_date' : this.getStartDate(), 'start_time': '', 'end_time' : ''}],
                    sleeping_nurse: 0
                    
                    
                   
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
               this.getClients();
               //this.getGrades();
               
            });
          
        },
        methods: {
            async getGradesForClient() {
                UtilityService.getSimpleApiData(this.accessToken, "grades?include_internal=1&client_id=" + this.client_id).then(
                    response => this.grades = response 
                );
            },
            
            addItem() {
                // Duplicate the last item in the array
                const lastItem = this.dates[this.dates.length - 1];
                const newItem = { ...lastItem }; // Use spread operator for a shallow copy

                // Optionally, you can modify properties of the newItem if needed
                // For example: newItem.name = 'New Name';

                // Add the newItem to the array
                this.dates.push(newItem);
            },
            async getClients() {
                UtilityService.getSimpleApiData(this.accessToken, "clients?exclude_risky=1").then(
                    response => this.clients = response 
                );
            },
            async getWards() {
                UtilityService.getSimpleApiData(this.accessToken, "wards?client_id=" + this.client_id).then(
                    response => this.wards = response 
                );
            },
            adjustBreak() {
                // Find the selected person and update the age input
                const selectedClient = this.clients.find(client => client.id === this.client_id);
                this.break_time = selectedClient ? selectedClient.default_break : '';
            }, 
            getStartDate() {
                var d = new Date();
                d.setDate(d.getDate());
                return d.toISOString().slice(0,10); 
            },
            getEndDate() {
                var d = new Date();
                d.setDate(d.getDate());
                return d.toISOString().slice(0,10);
            },
            
            async saveBooking() {
                this.working = true
                var formdata = { 
                    client_id : this.client_id,
                    ward_id : this.ward_id,
                    break_time : this.break_time,
                    grade_id : this.grade_id,
                    dates: this.dates,
                    sleeping_nurse: this.sleeping_nurse
                    
                }; 

                BookingService.saveBlockBooking(this.accessToken, formdata)
                .then((response) => {
                    
                    this.saved = true
                    this.working = false
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422 )
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        }
                        else if(error.response.status === 400 )
                        {
                            alert(JSON.stringify(error.response.data.error))
                            
                        }
                        else{
                            alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        }
                        
                        this.working = false
                });
            },
            clearForm()
            {
                this.client_id = '';
                this.ward_id = '';
                this.grade_id = ''
                this.break_time = 60;
                this.saved = false;
                this.dates = []
            }
           
        },
        watch: {
            client_id: function () {
                this.getWards()
                this.adjustBreak();

                if(this.client_id == 1667)
                {
                this.dates[0].reference = 'LPCH2178'
                }
            
            },
        }
    }
    </script>