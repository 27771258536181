<template>
    <div>
        <div class="is-pulled-right " >
            <input class="input" v-model="search" placeholder="search bookings ..."/>
        </div>
        
        <div class="notification is-warning" v-if="loading == true" style="clear:both">
            Loading bookings ...
        </div>
        <div class="notification is-danger" v-if="loading == false  && filtered_bookings.length == 0" style="clear:both">
            No bookings can be found matching your search criteria
        </div>

        

        <table class="table is-fullwidth is-striped is-hoverable is-size-7" v-if="loading == false && filtered_bookings.length > 0" style="clear:both">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Client</th>
                    <th>Ward</th>
                    <th>Reference</th>
                    <th>Grade</th>
                    <th>Member</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>User</th>
                    
                    <th width="40">&nbsp;</th>
                    <th width="40">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filtered_bookings" :key="row.id">
                    <td><a :href="'/bookings/' + row.id" target="_blank">{{ row.id }}</a></td>
                    
                    <td>{{ row.client_name }} <span class="tag is-warning" v-if="row.sleeping_nurse==1">Sleeping Nurse</span></td>
                    <td>{{ row.ward_name }}</td>
                    <td>{{ row.reference }}</td>
                    <td>{{ row.grade_code }}</td>
                    <td>{{ row.forename }} {{ row.surname  }}</td>
                    <td>{{ row.start_date }}</td>
                    <td>{{ row.start_time }} - {{ row.end_time }}</td>
                    <td>{{ row.user_forename }}</td>
                    <td>
                        <button class="button is-small is-success" :disabled="working==true" v-if="row.end_date_time > row.start_date_time" @click="confirmBooking(row.id)">Confirm</button>
                        <button class="button is-small is-danger" :disabled="working==true" title="End date is on or before start date" v-if="row.end_date_time <= row.start_date_time">Problem</button>
                       
                    </td>
                    <td><button class="button is-small is-danger" :disabled="working==true" @click="unassignBooking(row.id)">Un-Assign</button></td>
                </tr>
               
            </tbody>

        </table>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'AssignedBookings',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: false,
                   working:false,
                   showModal: false,
                   assigned:false,
                   alertText: '',
                   members: [],
                   accessToken: ''
            }
        },
        methods: {
            async getAssignedBookings() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "bookings/assigned")
                .then(
                    (bookings => {
                        this.$set(this, "bookings", bookings);
                        this.loading=false;
                        this.working = false;
                    }).bind(this)
                );
            },
            async confirmBooking(booking_id) {
                this.working = true
                UtilityService.postSimpleApiData(this.accessToken, "bookings/" + booking_id + "/confirm")
                .then(
                    () => {
                        this.getAssignedBookings();
                        this.working = false
                    }
                ).catch((error) => { 
                    
                    if (error.response.status == 409) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.response.data.message)
                    } 
                    this.working = false
                
                })
            },
            async unassignBooking(booking_id) {
                this.working = true

                var formdata = { 
                    _method: 'patch',
                    member_id : 0,
                }; 

                BookingService.updateBooking(this.accessToken, booking_id, formdata)
                .then(
                    () => {
                        this.working=false;
                       
                        this.getAssignedBookings();

                    }
                );
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getAssignedBookings();
            });
        
        },
        computed : {
            filtered_bookings: function () {
            
                let filtered_bookings =  this.bookings

                if(this.search != '' && this.search != null)
                {
                    filtered_bookings = filtered_bookings.filter(
                        row => (row.forename != null && row.forename.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.reference != null && row.reference.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.client_name != null && row.client_name.toLowerCase().includes(this.search.toLowerCase() ) )
                        || (row.id.toString() != null && row.id.toString().toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }
                return filtered_bookings
            },
        }
    }
</script>