<template>
    <div>
        <h2 class="title">Undecided what will appear here</h2>
        
        

        <div class="modal" :class="{'is-active': showModal==true}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Update Booking</p>
                <button class="delete" aria-label="close" @click="showModal=false"></button>
                </header>
                <section class="modal-card-body" v-if="assigned==false">
                    <table class="table is-fullwidth is-hoverable is-size-6">
                        <thead>
                            <tr>
                               
                                <th>Name</th>
                                <th>Interested</th>
                                 <th width="50">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in members" :key="'member' + row.id">
                                
                                <td>{{ row.forename }} {{ row.surname  }}</td>
                                <td><i class="fa fa-check"></i></td>
                                
                                <td><button class="button is-link is-small" @click="assigned=true">Assign</button></td>
                            </tr>
                            
                        </tbody>

                    </table>

                    
                </section>
                
                <footer class="modal-card-foot" >
                <button class="button" @click="showModal=false">Cancel</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
    
      name: 'MemberAlerts',
        mixins: [MemberMixin],
        components: {
           // MemberGradeTags
        },
        data: function () {
            return {
                   search: '',
                   alerts: [],
                   loading: false,
                   showModal: false,
                   members: []
            }
        },
        methods: {
            async getMemberAlerts() {
                this.loading=true
                this.bookings = []
                UtilityService.getSimpleApiData(this.accessToken, "member_alerts?interested=1")
                .then(
                    (alerts => {
                        this.$set(this, "alerts", alerts);
                        this.loading=false
                    }).bind(this)
                );
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberAlerts();
               
            });
        
        },
    }
</script>