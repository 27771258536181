<template>
    <div>
        
       

        <div v-if="searchPerformed==false">
            <form @submit.prevent="getBookings">
                <div class="field">
                    <label class="label">Search Booking ID </label>
                    <div class="control">
                        <input class="input" type="text" v-model="booking_id" autofocus >
                    </div>
                </div>
                <div class="field is-grouped">
                    <div class="control">
                        <button class="button is-success" type="submit" :disabled="working===true">Search</button>
                    </div>
                </div>
            </form>
        </div>
        
        <div v-if="searchPerformed==true">
            <button class="button is-link mb-4" type="button" :disabled="working===true" v-if="loading==false" @click="searchPerformed=false">Search Again</button>
            
            <div class="notification is-warning" v-if="loading == true" style="clear:both">
            Loading bookings ...
            </div>
            <div class="notification is-danger" v-if="loading == false  && filtered_bookings.length == 0" style="clear:both">
                No bookings can be found matching your search criteria
            </div>
            <table class="table is-fullwidth is-striped is-hoverable is-size-7" v-if="loading == false && filtered_bookings.length > 0" style="clear:both">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Reference</th>
                        <th>Client</th>
                        <th>Ward</th>
                        <th>Grade</th>
                        <th>Member</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Break</th>
                        <th width="40">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in filtered_bookings" :key="row.id">
                        <td><router-link :to="'/bookings/' + row.id">{{ row.id }}</router-link></td>
                        <td>{{ row.reference }}</td>
                        <td>{{ row.client_name }}</td>
                        <td>{{ row.ward_name }}</td>
                        <td>{{ row.grade_code }}</td>
                        <td><a :href="'/members/' + row.member_id" target="_blank">{{ row.member_forename }} {{ row.member_surname  }}</a></td>
                        <td>{{ row.start_date }}</td>
                        <td>{{ row.start_time }} - {{ row.end_time }}</td>
                        <td>{{ row.break_time }}
                        <td style="padding:0px"><router-link :to="'/bookings/' + row.id"><button class="button is-small is-success" :disabled="working==true" >View</button></router-link></td>
                    </tr>
                
                </tbody>

            </table>
        </div>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
//import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'AssignedBookings',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: false,
                   working:false,
                   showModal: false,
                   assigned:false,
                   alertText: '',
                   members: [],
                   accessToken: '',
                   booking_id: '',
                   searchPerformed : false
            }
        },
        methods: {
            async getBookings() {
                this.loading=true
                this.searchPerformed = true
                UtilityService.getSimpleApiData(this.accessToken, "bookings?booking_id=" + this.booking_id)
                .then(
                    (bookings => {
                        this.$set(this, "bookings", bookings);
                        this.loading=false;
                        this.working = false;
                        
                    }).bind(this)
                );
            },
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                
            });
        
        },
        computed : {
            filtered_bookings: function () {
            
                let filtered_bookings =  this.bookings

                if(this.search != '' && this.search != null)
                {
                    filtered_bookings = filtered_bookings.filter(
                        row => (row.forename != null && row.forename.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.client_name != null && row.client_name.toLowerCase().includes(this.search.toLowerCase() ) )
                        || (row.id.toString() != null && row.id.toString().toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }
                return filtered_bookings
            },
        }
    }
</script>