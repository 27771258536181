<script>

import MemberMixin from '@/mixins/MemberMixin.js';
import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
  data() {
    return {
      blocked_reason: this.member.training_blocked_reason
    }
  },
  methods: {
        assignBooking() {
            this.$emit('assignBookingFromButton')
        },
        setChecked() {
          this.$emit('checkBox')
        }
    },
    mixins: [MemberMixin],
  components: {
    MemberGradeTags
    },
  props: ['member', 'booking', 'user', 'working'],
  computed: {
    /*blocked: function ()  {
        let blocked = false
        if(this.member.training_blocked == 1)
        {
            blocked = true
        }

        if(this.booking.requires_niscc == 1 && this.member.niscc_status != 1)
        {
            // For senior care assistant roles, having NMC is okay
            if(this.booking.grade_id == 17 || this.booking.grade_id == 18)
            {
              if(this.member.nmc_status == 1)
              {
                // Allow them to work
              }
              else{
                blocked = true
              }
            }
            else{
              blocked = true
            }
           
        }
        if(this.booking.requires_nmc == 1 && this.member.nmc_status != 1)
        {
            blocked = true
        }

        return blocked
    }*/
  }
}


</script>



<template>
  
    <tr  :class="{'has-background-danger-dark' : member.blocked==1, 'has-text-white' : member.blocked==1}">
        <td><input type="checkbox" class="checkbox" v-model="member.checked"  v-if="member.blocked==0 && member.excluded==0 && member.already_working==0 && member.invalid==0 && member.training_blocked==false"/></td>
        <td><a :href="'/members/' + member.id"  target="_blank">{{ member.forename }} {{ member.surname  }}</a></td>
        <td>
            <MemberGradeTags :member="member" :restrict_grade_id="booking.grade_id"/>
        </td>
        
        <td>
            <div class="tag is-success is-small" v-if="member.available == 1">Yes</div>
            <div class="tag is-danger is-small" v-if="member.available == 0">No</div>
        </td>
        <td>
            <div class="tag is-danger is-small" v-if="member.nmc_status == 0">None</div>
            <div class="tag is-success is-small" v-if="member.nmc_status == 1">Valid</div>
            <div class="tag is-warning is-small" v-if="member.nmc_status == 2">Expired</div>
        </td>
        <td>
            <div class="tag is-danger is-small" v-if="member.niscc_status == 0">None</div>
            <div class="tag is-success is-small" v-if="member.niscc_status == 1">Valid</div>
            <div class="tag is-warning is-small" v-if="member.niscc_status == 2">Expired</div>
        </td>
        <td>
            <div class="tag is-danger is-small" v-if="member.accessni_status == 0">None</div>
            <div class="tag is-success is-small" v-if="member.accessni_status == 1">Valid</div>
            <div class="tag is-warning is-small" v-if="member.accessni_status == 2">Expired</div>
        </td>
        <td>
            <div class="tag is-success is-small" v-if="member.interested == 1">Yes</div>
            
        </td>
        <td>
          <button class="button is-danger is-small" v-if="member.blocked == 1">BLOCKED</button> <!-- Nothing you can do about this -->
          <button class="button is-warning is-small" v-else-if="member.excluded == 1">Excluded</button>
          <button class="button is-danger is-small" v-else-if="member.already_working == 1" :title="member.already_working_booking_id">Working</button>
          <button class="button is-warning is-small" v-else-if="member.invalid == 1" :title="member.invalid_reason">Invalid</button>
          <button class="button is-warning is-small" v-else-if="member.training_blocked == 1" :title="member.training_blocked_reason">Training</button>
          
          <button class="button is-link is-small" @click="assignBooking(member)" :disabled="working==true" v-if="member.blocked==0 && member.excluded==0 && member.already_working==0 && member.invalid==0 && member.training_blocked==false">Assign</button>
          <button class="button is-danger is-small" @click="assignBooking(member)" :disabled="working==true" v-if="member.blocked==0 && member.excluded==0 && member.already_working==0 && member.invalid==0 && member.training_blocked==true && user.admin==1" :title="member.training_blocked_reason" >Assign</button>
            
        </td>
    </tr>
    
    
  
</template>