<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading bookings ...
        </div>
        <div class="notification is-danger" v-if="loading == false && this.searchPerformed == true && bookings.length == 0">
            No bookings can be found matching your search criteria
        </div>

        <table class="table is-fullwidth is-striped is-hoverable" v-if="loading == false && bookings.length > 0">
            <thead>
                <tr>
                    <th>Client</th>
                    <th>Ward</th>
                    <th>Grade</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th width="40">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in bookings" :key="row.id">
                    <td>{{ row.client_name }}</td>
                    <td>{{ row.ward_name }}</td>
                    <td>{{ row.grade_code }}</td>
                    <td>{{ row.start_date }}</td>
                    <td>{{ row.start_time }} - {{ row.end_time }}</td>
                    <td><button class="button is-small is-success" @click="actionBooking(row)">Action</button></td>
                </tr>
               
            </tbody>

        </table>

        <div class="modal" :class="{'is-active': showModal==true}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Update Booking</p>
                <button class="delete" aria-label="close" @click="showModal=false"></button>
                </header>
                <section class="modal-card-body" v-if="assigned==false">
                    <div class="notification is-warning" v-if="loading == true">
                        Loading members ...
                    </div>
                    <div class="notification is-danger" v-if="loading == false  && members.length == 0">
                        There are not matching members for this booking
                    </div>
                    <table class="table is-fullwidth is-hoverable is-size-6" v-if="loading == false && members.length > 0">
                        <thead>
                            <tr>
                               
                                <th>Name</th>
                                <th>Interested</th>
                                 <th width="50">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in members" :key="'member' + row.id">
                                
                                <td>{{ row.forename }} {{ row.surname  }}</td>
                                <td><i class="fa fa-check"></i></td>
                                
                                <td><button class="button is-link is-small" @click="assignBooking(row)" :disabled="working==true">Assign</button></td>
                            </tr>
                            
                        </tbody>

                    </table>

                    
                </section>
                
                <footer class="modal-card-foot" >
                <button class="button" @click="showModal=false">Cancel</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';
import BookingService from '@/services/BookingService.js';

export default {
    
      name: 'PendingBookings',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: false,
                   searchPerformed: false,
                   showModal: false,
                   assigned:false,
                   alertText: '',
                   members: [],
                   selected_booking: [],
            }
        },
        methods: {
            async getNewBookings() {
                this.loading=true
                this.bookings = []
                UtilityService.getSimpleApiData(this.accessToken, "bookings/pending")
                .then(
                    (bookings => {
                        this.$set(this, "bookings", bookings);
                        this.loading=false
                    }).bind(this)
                );
            },
            async actionBooking(booking) {
                this.selected_booking = booking

                this.getMembers();

                this.showModal = true
            },
            async assignBooking(member) {
                this.working = true

                var formdata = { 
                    _method: 'patch',
                    member_id : member.id,
                }; 

                BookingService.updateBooking(this.accessToken, this.selected_booking.id, formdata)
                .then(
                    () => {
                        this.working=false;
                        this.showModal=false;

                        this.getNewBookings();

                    }
                );
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getNewBookings();
                //this.getMembers();
            });
        
        },
    }
</script>