<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading patients ...
        </div>
        <div class="notification is-danger" v-if="loading == false && filtered_patients.length == 0">
            No patients can be found.
        </div>

        <div class="level">
            <div class="level-left">
                <router-link to="/add-patient"><button class="button is-success">Add New Patient</button></router-link>
            </div>
            <div class="level-right " style="">
                
                <input class="input" v-model="search" placeholder="search patients ..."/>
            
            </div>
        </div>

        <div v-if="loading == false && filtered_patients.length > 0">
            <h4 class="title is-size-4">Active Patients</h4>
            <table class="table is-fullwidth is-striped is-hoverable is-size-7" >
                <thead>
                    <tr>
                        <th>Patient ID</th>
                        <th>Forename</th>
                        <th>Surname</th>
                        <th>Postcode</th>
                        <th>DOB</th>
                        <th>Project</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in filtered_patients" :key="row.id">
                        <td><router-link :to="'/patients/' + row.id">{{ row.patient_id  }}</router-link></td>
                        <td><router-link :to="'/patients/' + row.id">{{ row.forename  }}</router-link></td>
                        <td><router-link :to="'/patients/' + row.id">{{ row.surname }}</router-link></td>
                        <td>{{ row.postcode }}</td>
                        <td>{{ row.dob }}</td>
                        <td>{{ row.project_name }}</td>
                        <td></td>
                        <td></td>
                    </tr>
                
                </tbody>

            </table>
        </div>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
//import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'ListPatients',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   patients: [],
                   loading: true,
                   alertText: '',
                   accessToken: '',
                   working: false
                   
            }
        },
        methods: {
            async getAllPatients() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "patients")
                .then(
                    (patients => {
                        this.$set(this, "patients", patients);
                        this.loading=false
                    }).bind(this)
                );
            },
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getAllPatients();
                
            });
        
        },
        computed : {
            filtered_patients: function () {
            
                let filtered_patients =  this.patients

                if(this.search != '' && this.search != null)
                {
                    filtered_patients = filtered_patients.filter(
                        row => (row.forename != null && row.forename.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.patient_id != null && row.patient_id.toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }

                return filtered_patients
            
            },
            
            
        }
    }
</script>