<template>
    <div class="modal " :class="{'is-active': showCancelModal==true}">
        <div class="modal-background"></div>
        <div class="modal-card"  style="width:840px">
            <header class="modal-card-head">
                <p class="modal-card-title">Cancel Booking</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body" >
                <div class="notification is-warning" v-if="loading == true">
                    Loading booking details ...
                </div>
                <form @submit.prevent="cancelBooking()" v-if="loading==false">
                    <div class="field">
                    <label class="label">Booking ID</label>
                    <div class="control">
                        <input class="input"  :value="this.booking.id" disabled>
                    </div>
                    </div>
                    
                    <div class="field">
                    <label class="label">Cancel Type</label>
                    <div class="select is-fullwidth">
                        <select class="input "  v-model="booking_status_id" required>
                            <option value="6">Cancelled By Client</option>
                            <option value="7">Cancelled By Member</option>
                            <option value="8">Cancelled By Balmoral</option>
                        </select>
                    </div>
                    </div>

                    <div class="field">
                    <label class="label">Cancellation Reason</label>
                    <div class="control">
                        <input class="input" type="text" v-model="reason" required>
                    </div>
                    </div>
                   
                    <div class="field is-grouped">
                    <div class="control">
                        <button class="button is-success" :disabled="working==true">Cancel Booking</button>
                    </div>
                    
                    </div>
                </form>

                
            </section>
            
            <footer class="modal-card-foot" >
            <button class="button" @click="closeModal()" :disabled="working==true">Close</button>
            </footer>
        </div>
        
       
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
    
      name: 'NoteModal',
        mixins: [MemberMixin],
        components: {
            
        },
        props: ['booking_id', 'showCancelModal'],
        data: function () {
            return {
                   search: '',
                   booking: [],
                   searchPerformed: false,
                   assigned:false,
                   alertText: '',
                   working:false,
                   loading: true,
                   reason: '',
                   booking_status_id: ''
            }
        },
        methods: {
            async getBooking() {
                this.loading=true
                
                UtilityService.getSimpleApiData(this.accessToken, "bookings/" + this.booking_id)
                .then(
                    (booking => {
                        this.$set(this, "booking", booking);
                        this.loading = false
                    }).bind(this)
                );
            },
            
            async cancelBooking() {
                this.working = true

                    var formdata = { 
                        booking_status_id : this.booking_status_id,
                        reason : this.reason
                    }; 

                    BookingService.cancelBooking(this.accessToken, this.booking_id, formdata)
                    .then(
                        () => {
                            this.working=false;
                            
                            alert("Booking Cancelled")
                            this.closeModal()

                        }
                    ).catch((error) => { 
                        
                    if (error.response.status == 409) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.response.data.message)
                    } 
                    this.working=false;
                });
                
            },
            
            async closeModal() {
                this.reason = ''
                this.booking_status_id = ''
                
                this.$emit('close')
            },
            
        },
        computed : {
           
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                
            });
        
        },
        watch: {
            
            showCancelModal() {
                
                this.getBooking();
                
            }
        },
    }
</script>