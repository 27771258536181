<template>
    <div>
        <div class="level " >
            <div class="level-item mr-4">
            <select v-model="time_period_id" class="input">
                <option value="">Select Specific Time Period</option>
                <option  v-for="row in time_periods" :key="row.id" :value="row.id">{{ row.name}}</option>
            </select>
            </div>
            <div class="level-item">
            
            <input class="input" v-model="search" placeholder="search members ..."/>

            </div>
        </div>
        
        <div class="notification is-warning" v-if="loading == true" style="clear:both">
            Loading availability ...
        </div>
        <div class="notification is-danger" v-if="loading == false  && filtered_availability.length == 0" style="clear:both">
            No availability can be found matching your search criteria
        </div>

        

        <table class="table is-fullwidth  is-hoverable is-size-7" v-if="loading == false && filtered_availability.length > 0" style="clear:both">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Time Period</th>
                    
                    <th width="40">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(groupedRow, index) in groupedData">
                    <tr :key="'header' + index" class="is-selected">
                        <td colspan="4"><strong>{{ index }}</strong></td>
                    </tr>
                    <tr v-for="(row, index2) in groupedRow" :key="'data' + index + '-' + index2">
                        
                        <td>{{ row.date }}</td>
                        <td>{{ row.forename }} {{ row.surname}}</td>
                        <td>{{ row.timePeriods }}</td>
                        
                        <td><router-link :to="'/members/' + row.member_id" target="_blank"><button class="button is-small is-link" :disabled="working==true" >View</button></router-link></td>
                    </tr>
                </template>
               
            </tbody>

        </table>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
//import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'AvailabilityReport',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   loading: false,
                   working:false,
                   showModal: false,
                    availability: [],
                   accessToken: '',
                   time_period_id: '',
                   time_periods: [],
            }
        },
        methods: {
            async getAvailability() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "member_availabilitys")
                .then(
                    (availability => {
                        this.$set(this, "availability", availability);
                        this.loading=false;
                        this.working = false;
                    }).bind(this)
                );
            },
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getAvailability();
                this.getTimePeriods();
            });
        
        },
        computed : {
            filtered_availability() {
                    const filtered_availability = {};

                    let filtered_data = this.availability;
                    if(this.search != '' && this.search != null)
                    {
                        filtered_data = filtered_data.filter(
                            row => (row.forename != null && row.forename.toLowerCase().includes(this.search.toLowerCase() ) ) 
                            || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() )) 
                        )
                    }

                    if(this.time_period_id != '' && this.time_period_id != null && this.time_period_id > 0)
                    {
                        filtered_data = filtered_data.filter(
                            row => (row.time_period_id != null && row.time_period_id == this.time_period_id ) 
                        )
                    }
                   

                    filtered_data.forEach(item => {
                    const key = `${item.member_id}-${item.forename}-${item.surname}-${item.date}`;
                    if (!filtered_availability[key]) {
                        filtered_availability[key] = {
                            member_id: item.member_id,
                            forename: item.forename,
                        surname: item.surname,
                        date: item.date,
                        timePeriods: [item.time_period_name]
                        };
                    } else {
                        filtered_availability[key].timePeriods.push(item.time_period_name);
                    }
                    });

                    // Convert object values to array
                    const combinedArray  = Object.values(filtered_availability);

                    // Sort by date
                    combinedArray .sort((a, b) => {
                    return new Date(a.date) - new Date(b.date);
                    });

                    return combinedArray ;
                },
                groupedData() {
                    const groupedObject = {};
                    this.filtered_availability.forEach(item => {
                    if (!groupedObject[item.date]) {
                        groupedObject[item.date] = [item];
                    } else {
                        groupedObject[item.date].push(item);
                    }
                    });
                    
                    return groupedObject;
                }
            /*    filtered_availability : function () {
            
                let filtered_availability =  this.availability

                if(this.search != '' && this.search != null)
                {
                    filtered_bookings = filtered_bookings.filter(
                        row => (row.forename != null && row.forename.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.reference != null && row.reference.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.client_name != null && row.client_name.toLowerCase().includes(this.search.toLowerCase() ) )
                        || (row.id.toString() != null && row.id.toString().toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }
                return filtered_availability
            },*/
        }
    }
</script>