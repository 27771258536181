<template>
    <div>
        <div class="is-pulled-right " >
            <div class="columns">
                <div class="column">
                    <input class="input" type="date" v-model="start_date" @change="getBookings()"/>
                </div>
                <div class="column">
                    <input class="input" v-model="search" placeholder="search bookings ..."/>
                </div>
            </div>
            
            
        </div>

        <div class="notification is-warning" v-if="loading == true" style="clear:both">
            Loading bookings ...
        </div>
        <div class="notification is-danger" v-if="loading == false  && filtered_bookings.length == 0" style="clear:both">
            No bookings can be found matching your search criteria
        </div>

        <table class="table is-fullwidth is-striped is-hoverable is-size-7" v-if="loading == false && filtered_bookings.length > 0" style="clear:both">
            <thead>
                <tr>
                        <th width="20"></th>
                        <th width="20">ID</th>
                    <th>Client</th>
                    <th>Ward</th>
                    <th>Reference</th>
                    <th>Grade</th>
                    <th>Member</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>User</th>
                    <th width="40">&nbsp;</th>
                    <th width="20"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filtered_bookings" :key="row.id">
                    <td>
                        <span class="icon pointer" @click="showLogsModal(row.id)" >
                            <i class="fa fa-bars"></i>
                        </span>
                    </td>
                    <td><a :href="'/bookings/' + row.id" target="_blank">{{ row.id }}</a></td>
                    <td>{{ row.client_name }} <span class="tag is-warning" v-if="row.sleeping_nurse==1">Sleeping Nurse</span></td>
                    <td>{{ row.ward_name }}</td>
                    <td>{{ row.reference }}</td>
                    <td>{{ row.grade_code }}</td>
                    <td><a :href="'/members/' + row.member_id" target="_blank">{{ row.forename }} {{ row.surname  }}</a></td>
                    <td>{{ row.start_date }}</td>
                    <td>{{ row.start_time }} - {{ row.end_time }}</td>
                    <td>{{ row.user_forename }}</td>
                    <td><button class="button is-small is-danger" :disabled="working==true" @click="openCancelModal(row.id)" v-if="row.timesheet_saved ==0  && row.timesheet_submitted ==0 ">Cancel</button></td>
                    <td><input type="checkbox" /></td>
                </tr>
               
            </tbody>

        </table>

        <BookingLogsModel :showModal="showModal" @closeModal="closeModal" :booking_id="booking_id"/>

        <CancelModal :booking_id="this.booking_id" :showCancelModal="showCancelModal" @close="closeCancelModal"/>
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
//import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

import BookingLogsModel from '@/components/Bookings/BookingLogsModel.vue';
import CancelModal from './CancelModal.vue'

export default {
    
      name: 'CompletedBookings',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: false,
                   working:false,
                   showModal: false,
                   assigned:false,
                   alertText: '',
                   members: [],
                   booking_id: '',
                   showCancelModal: false,
                   start_date: this.getStartDate()
            }
        },
        components: {
            BookingLogsModel,
            CancelModal
        },
        methods: {
            showLogsModal(booking_id) {
                this.booking_id = booking_id
                this.showModal = true
                
            },
            openCancelModal(booking_id) {
                this.booking_id = booking_id
                this.showCancelModal = true
                
            }, 
            async getBookings() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "bookings/confirmed?start_date=" + this.start_date)
                .then(
                    (bookings => {
                        this.$set(this, "bookings", bookings);
                        this.loading=false;
                        this.working = false;
                    }).bind(this)
                );
            },
            getStartDate() {
            
                var d = new Date();
                
                d.setDate(d.getDate() - 5);
                return d.toISOString().slice(0,10);
                
            },
            
           
            
        },
        computed : {
            filtered_bookings: function () {
            
                let filtered_bookings =  this.bookings

                if(this.search != '' && this.search != null)
                {
                    filtered_bookings = filtered_bookings.filter(
                        row => (row.forename != null && row.forename.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.reference != null && row.reference.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.client_name != null && row.client_name.toLowerCase().includes(this.search.toLowerCase() ) )
                        || (row.ward_name != null && row.ward_name.toLowerCase().includes(this.search.toLowerCase() ) )
                        || (row.id.toString() != null && row.id.toString().toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }
                return filtered_bookings
            },
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getBookings();
                
            });
        
        },
    }
</script>