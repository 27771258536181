<template>
    <div class="home">
        <router-link to="/patients" class="is-pulled-right"><button class="button is-info is-small">Back to Patient List </button></router-link><br /><br />
        <div class="box">	
            <h2 class="title is-size-4">Patient Details</h2>
            
            <div class="notification is-info" v-if="loading === true">
                <p>Loading patient details ...</p>
            </div>
            
            <form @submit.prevent="updatePatient" v-if="loading===false">
                <div class="field">
                    <label class="label">Patient ID </label>
                    <div class="control">
                        <input class="input" type="text" v-model="patient.patient_id"  >
                    </div>
                </div>

                <div class="field">
                    <label class="label">Forename</label>
                    <div class="control">
                        <input class="input" type="text" v-model="patient.forename"  >
                    </div>
                </div>

                <div class="field">
                    <label class="label">Surname</label>
                    <div class="control">
                        <input class="input" type="text" v-model="patient.surname"  >
                    </div>
                </div>

                <div class="field">
                    <label class="label">Postcode</label>
                    <div class="control">
                        <input class="input" type="text" v-model="patient.postcode"  >
                    </div>
                </div>
      
                <div class="field">
                    <label class="label">Date of Birth</label>
                    <div class="control">
                        <input class="input" type="date" v-model="patient.dob_raw"  >
                    </div>
                </div>
      
                
                <div class="field">
                    <label class="label">Project</label>
                    <div class="control">
                        <select class="input" v-model="patient.project_id"  required>
                        <option value="">Please select ...</option>
                        <option :value="row.id" v-for="row in projects" :key="row.id">{{ row.name }}</option>
                        
                    </select>
                        
                    </div>
                </div>


                    <div class="field is-grouped" style="display:none">
                        <div class="control">
                            <button class="button is-success" :disabled="working===true">Update Patient</button>
                        </div>
                    </div>
                </form>
            
          </div>

          <div class="box">
            <h2 class="title is-size-4">Patient Bookings</h2>
            
            <div class="notification is-info" v-if="loading === true">
                <p>Loading patient bookings ...</p>
            </div>

            <div class="notification is-warning" v-if="loading == false && patient.bookings.length == 0">
                There are no bookings linked to this patient yet.
            </div>

            <div  v-if="loading == false && patient.bookings.length > 0">
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Booking ID</th>
                            <th>Member</th>
                            <th>Date</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in patient.bookings" :key="row.id">
                            <td><router-link :to="'/bookings/' + row.booking_id" target="_blank">{{ row.booking_id }}</router-link></td>
                            <td>{{  row.member_forename }} {{  row.member_surname }}</td>
                            <td>{{ row.booking_date }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
          </div>
          
      </div>
  </template>

<script>
    import MemberService from '@/services/MemberService.js';
    import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'ViewPatient',
        mixins: [],
        data: function () {
            return {
                   loading : true,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    projects: [],
                    patient: [],
                    
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
                this.getPatient();
               this.getProjects();
               
            });
          
        },
        methods: {
            async getProjects() {
                UtilityService.getSimpleApiData(this.accessToken, "projects").then(
                    response => this.projects = response 
                );
                
            },
           
            async getPatient() {
                
                    MemberService.getPatient(this.accessToken, this.$route.params.id)
                    .then(
                        (patient => {
                             this.$set(this, "patient", patient);
                        this.loading=false
                        }).bind(this)
                    );
                
            },

            async updatePatient() {
                this.working = true
                var formdata = { 
                    _method: 'patch',
                    
                }; 

                MemberService.updateStone(this.accessToken, formdata, this.stone.id)
                .then((response) => {
                    this.$router.push("/stones");
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
           
        }
    }
    </script>