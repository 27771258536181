<template>
    <div>
        <div class="mb-4">
            <select v-model="selectedMonth" class="input">
                <option v-for="(option, index) in months" :key="index" :value="option">
                    {{ option.dateLabel }}
                </option>
                </select>
        </div>
        
        <div class="notification is-warning" v-if="loading == true">
            Loading bookings ...
        </div>
        <div class="notification is-danger" v-if="loading == false  && bookings.length == 0">
            No bookings can be found matching your search criteria
        </div>

        <div v-if="loading==false && bookings.length > 0">
            <div class="columns">
                <div class="column">
                    <div class="box has-background-success has-text-white">Total Hours : {{ totalHours }}</div> 
                </div>
                <div class="column">
                    <div class="box has-background-warning has-text-black">Total Shifts : {{ bookings.length }}</div> 
                </div>
                <div class="column">
                    <div class="box has-background-link has-text-white">Total Members : {{ uniqueMemberCount }}</div> 
                </div>
            </div>

            
            <h4 class="title is-size-4">Summary</h4>
            <table class="table is-fullwidth mb-6">
                <thead>
                    <tr>
                        <th>Grade</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tr v-for="row in uniqueGrades" :key="row.grade">
                    <td>{{  row.grade }}</td>
                    <td>{{ row.total}}</td>
                </tr>
            </table>
            

            <h4 class="title is-size-4">All Shifts</h4>
            <table class="table is-fullwidth is-striped is-hoverable is-size-7" >
                <thead>
                    <tr>
                            <th width="20"></th>
                            <th width="20">ID</th>
                        <th>Client</th>
                        <th>Ward</th>
                        <th>Grade</th>
                        <th>Member</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Hours</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in bookings" :key="row.id">
                        <td>
                            <span class="icon pointer" @click="showLogsModal(row.id)" >
                                <i class="fa fa-bars"></i>
                            </span>
                        </td>
                        <td><router-link :to="'/bookings/' + row.id">{{ row.id }}</router-link></td>
                        <td>{{ row.client_name }}</td>
                        <td>{{ row.ward_name }}</td>
                        <td>{{ row.grade_code }}</td>
                        <td>{{ row.forename }} {{ row.surname  }}</td>
                        <td>{{ row.start_date }}</td>
                        <td>{{ row.start_time }} - {{ row.end_time }}</td>
                        <td>{{ parseFloat(row.total_mins_worked / 60).toFixed(2) }}</td>
                        
                    </tr>
                
                </tbody>

            </table>
        </div>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
//import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';



export default {
    
      name: 'MonthlyStats',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: false,
                   working:false,
                   showModal: false,
                   assigned:false,
                   alertText: '',
                   members: [],
                   booking_id: '',
                   showCancelModal: false,
                   selectedMonth: { 
                        
                    },
                   months: [
                    ],
                    accessToken: ''
            }
        },
        components: {
            
        },
        methods: {
            showLogsModal(booking_id) {
                this.booking_id = booking_id
                this.showModal = true
                
            },
            openCancelModal(booking_id) {
                this.booking_id = booking_id
                this.showCancelModal = true
                
            }, 
            async getBookings() {
                if(this.selectedMonth.startDate != undefined)
                {
                    this.loading=true
                    UtilityService.getSimpleApiData(this.accessToken, "bookings/confirmed?include_hours=1&start_date=" + this.selectedMonth.startDate + "&end_date=" + this.selectedMonth.endDate )
                    .then(
                        (bookings => {
                            this.$set(this, "bookings", bookings);
                            this.loading=false;
                            this.working = false;
                        }).bind(this)
                    );
                }
            },
            async getMonths() {
                 UtilityService.getSimpleApiData(this.accessToken, "stats_months" )
                .then(
                    (months => {
                        this.$set(this, "months", months);
                    }).bind(this)
                );
            },
            async getCurrentMonth() {
                 UtilityService.getSimpleApiData(this.accessToken, "stats_months?current_month=1" )
                .then(
                    (selectedMonth => {
                        this.$set(this, "selectedMonth", selectedMonth);
                    }).bind(this)
                );
            },
           
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                
                this.getMonths();
                this.getCurrentMonth();

                this.getBookings();
            });
        
        },
        computed: {
            totalHours() {
                let hours = this.bookings.reduce((total, booking) => total + booking.total_mins_worked, 0) /60;
                return parseFloat(hours).toFixed(2);
            },
            uniqueMemberCount() {
                const uniqueItems = new Set();
                
                // Iterate through the itemList and add unique items to the Set
                this.bookings.forEach(item => {
                    uniqueItems.add(item.member_id);
                });

                // Return the size of the Set, which represents the number of unique items
                return uniqueItems.size;
            },
            uniqueGrades() {
                const gradeCounts = {};
                
                // Count the occurrences of each grade
                this.bookings.forEach(item => {
                    const grade = item.grade_code;
                    if (gradeCounts[grade]) {
                    gradeCounts[grade]++;
                    } else {
                    gradeCounts[grade] = 1;
                    }
                });
                
                // Transform the counts into an array of objects
                const uniqueGrades = [];
                for (const grade in gradeCounts) {
                    uniqueGrades.push({
                    grade: grade,
                    total: gradeCounts[grade]
                    });
                }
                
                return uniqueGrades;
            }
            
        },
        watch: {
            selectedMonth: function(){
                this.getBookings();
            },
            
        }
    }
</script>