<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading holidays ...
        </div>
        <div class="notification is-danger" v-if="loading == false && holidays.length == 0">
            No holidays can be found in the database
        </div>

       
        <table class="table is-fullwidth is-striped is-hoverable" v-if="loading == false && holidays.length > 0">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Client</th>
                    <th width="50">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in holidays" :key="row.id" :class="{'has-background-grey-lighter':row.future==0}">
                     <td>{{ row.date }}</td>
                     <td>{{ row.description }}</td>
                    <td>{{ row.client_name }}</td>
                    <td></td>
                </tr>
               
            </tbody>

        </table>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'ClientGroups',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   holidays: [],
                   loading: false,
                   accessToken: ''
                  
            }
        },
        methods: {
            async getHolidays() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "holidays")
                .then(
                    (holidays => {
                        this.$set(this, "holidays", holidays);
                        this.loading=false
                    }).bind(this)
                );
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getHolidays();
                
            });
        
        },
        computed : {
            
        }
    }
</script>