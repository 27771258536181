<template>
    <div class="home">
        <div class="box">	
            <div class="notification is-info" v-if="loading === true">
                <p>Loading booking details ...</p>
            </div>
            <div class="notification is-danger" v-if="loading === false && Object.keys(this.booking).length == 0">
                <p>Booking could not be found ...</p>
            </div>

            <div v-if="loading === false && Object.keys(this.booking).length > 0" class="content"> 
                <h4 class="title is-size-4">Booking Details - {{ booking.id}}</h4>
                <p>Please note that you are updating the original booking details only. If a timesheet has already been saved or submitted, Cathy will need to also update the "actual" dates, times and break times.</p>
                <form @submit.prevent="updateBooking" >
                    <div class="columns">
                        
                        <div class="column">
                            <div class="field">
                                <label class="label">Reference</label>
                                <div class="control">
                                    <input class="input" type="text" v-model="booking.reference"  >  
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label class="label">Grade</label>
                                <div class="control">
                                    <select class="input" v-model="booking.grade_id"  required>
                                        <option value="">Please select ...</option>
                                        <option :value="row.id" v-for="row in grades" :key="'grade' + row.id">{{ row.name }}</option>
                                    
                                    </select>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label class="label">Client</label>
                                <div class="control">
                                    <select class="input" v-model="booking.client_id"  required @change="updateWards()">
                                        <option value="">Please select ...</option>
                                        <option :value="row.id" v-for="row in clients" :key="'client' + row.id">{{ row.name }}</option>
                                    
                                    </select>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label class="label">Ward</label>
                                <div class="control">
                                    <select class="input" v-model="booking.ward_id" required >
                                        <option value="0">No ward required</option>
                                        <option :value="row.id" v-for="row in wards" :key="'ward' + row.id">{{ row.name }}</option>
                                    
                                    </select>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div> 
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label">Original Start Date </label>
                                <div class="control">
                                    <input class="input" type="date" v-model="booking.start_date" required >  
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label class="label">Original Start Time</label>
                                <div class="control">
                                    <input class="input" type="time" v-model="booking.start_time" required >  
                                </div>
                            </div>
                        </div>
                    
                       
                        <div class="column">
                            <div class="field">
                                <label class="label">Original End Time</label>
                                <div class="control">
                                    <input class="input" type="time" v-model="booking.end_time" required >  
                                </div>
                            </div>
                        </div>

                        <div class="column">
                            <div class="field">
                                <label class="label">Original Break Time</label>
                                <div class="control">
                                    <input class="input" type="text" v-model="booking.break_time" required >  
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="columns" v-if="booking.timesheet_processed == 1">
                        <div class="column">
                            <div class="field">
                                <label class="label">Actual Start Date </label>
                                <div class="control">
                                    <input class="input" type="date" v-model="booking.actual_start_date" required >  
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label class="label">Actual Start Time</label>
                                <div class="control">
                                    <input class="input" type="time" v-model="booking.actual_start_time" required >  
                                </div>
                            </div>
                        </div>
                    
                       
                        <div class="column">
                            <div class="field">
                                <label class="label">Actual End Time</label>
                                <div class="control">
                                    <input class="input" type="time" v-model="booking.actual_end_time" required >  
                                </div>
                            </div>
                        </div>

                        <div class="column">
                            <div class="field">
                                <label class="label">Actual Break Time</label>
                                <div class="control">
                                    <input class="input" type="text" v-model="booking.actual_break_time" required >  
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="field is-grouped" v-if="booking.invoice_sent == 0 ">
                        <div class="control">
                            <button class="button is-success" :disabled="working===true">Update Booking</button>
                        </div>
                    </div> 
                    <div class="field is-grouped" v-if="booking.invoice_sent == 1 ">
                        <div class="control">
                            <button class="button is-danger" :disabled="working===true">Invoice Has Already Been Sent</button>
                        </div>
                    </div>   
                </form>

                <div v-if="booking.allow_sleeping_nurse == 1">
                    <hr />
                    <h4 class="title is-size-4">Sleeping Nurse Options</h4>
                    
                    <div v-if="booking.sleeping_nurse == 1">
                        <p>The sleeping nurse option is ON for this booking. Click the button below to turn it off.</p>
                        <form @submit.prevent="updateSleepingNurse(0)" class="mt-2">
                       
                            <div class="field ">
                                <button class="button is-danger" type="submit" :disabled="working===true" >Turn OFF sleeping nurse</button>
                            </div>
                        </form>
                    </div>

                    <div v-if="booking.sleeping_nurse == 0">
                        <p>The sleeping nurse option is OFF for this booking. Click the button below to turn it off.</p>
                        <form @submit.prevent="updateSleepingNurse(1)" class="mt-2">
                       
                            <div class="field ">
                                <button class="button is-success" type="submit" :disabled="working===true" >Turn ON sleeping nurse</button>
                            </div>
                        </form>
                    </div>
                    
                    

                    
                </div>

                <div v-if="loading==false && booking.community_nursing == 1">
                    <hr />
                    
                    <div v-if="this.booking.patients.length == 0" class="notification is-warning">
                        This booking has not been linked to any patients yet.
                    </div>

                    <div v-if="this.booking.patients.length > 0">
                        <p>This booking has been linked to the following patients.</p>
                        <div class="buttons mt-3">
                            <button  v-for="patient in this.booking.patients" :key="patient.id" class="button mb-3 is-link">
                                {{  patient.forename }} {{ patient.surname }}
                            </button>
                        </div>
                    </div>

                    <hr />
                    <h3 class="title is-4">Add a New Patient</h3>
                    <div class="field select">
                        <select v-model="patient_id" >
                            <option value="">Select patient</option>
                            <option v-for="row in filteredPatients" :key="row.id" :value="row.id">{{ row.forename }} {{ row.surname}}</option>
                        </select>
                    </div>
                    <div class="field ">
                        <div class="control">
                            <button class="button is-success" :disabled="working===true" @click="saveBookingPatient()">Add Patient</button>
                        </div>
                    </div>

                    <div v-if="this.booking.patients.length > 0" >
                        <hr />
                        <h3 class="title is-4 has-text-danger">REMOVE a Patient</h3>
                        <div class="field select">
                            <select v-model="remove_booking_patient_id" >
                                <option value="">Select patient to remove</option>
                                <option v-for="row in this.booking.patients" :key="row.id" :value="row.id">{{ row.forename }} {{ row.surname }}</option>
                            </select>
                        </div>
                        <div class="field ">
                            <div class="control">
                                <button class="button is-danger" :disabled="working===true" @click="removeBookingPatient()">Remove Patient</button>
                            </div>
                        </div>
                    </div>
                </div>


                
                <div v-if="booking.member_id > 0">
                    <hr />
                    <div v-if="booking.booking_status_id == 3">
                        <h4 class="title is-size-4">Booking Has Been Assigned</h4>
                    </div>
                    <div v-if="booking.booking_status_id == 4">
                        <h4 class="title is-size-4">Booking Has Been Confirmed</h4>
                    </div>
                    
                    
                    <form >
                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Member Forename </label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="booking.member_forename"  >  
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Member Surname</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="booking.member_surname"  >  
                                    </div>
                                </div>
                            </div>
                        </div>    
                        <div v-if="booking.booking_status_id == 4">
                            <div class="field is-grouped" v-if="booking.invoice_sent == 0 ">
                                <div class="column">
                                    <button class="button is-success" :disabled="working===true" @click="reconfirmBooking()">Re-Send Timesheet Email To Member</button>
                                </div>
                                <div class="column">
                                    <a :href="API_NAME + '/bookings/' + booking.id + '/timesheet'" target="_blank"><button type="button" class="button is-warning" :disabled="working===true" >Download Original Timesheet</button></a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

               
                
                <div v-if="user.billing == 1 && booking.timesheet_processed == 1">
                    <hr />
                    <h4 class="title is-size-4">Timesheet Has Been Processed</h4>
                    <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Processed Week </label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="booking.week" readonly >  
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Processed Year</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="booking.year" readonly  >  
                                    </div>
                                </div>
                            </div>
                        </div> 

                    
                    
                        <div class="columns">
                            <div class="column">
                                <form @submit.prevent="resendInvoice()" v-if="booking.reference != '' && booking.client_type_id != 4">
                                    <div class="row">
                                        <div class="field">
                                            <label class="label">Invoice Email</label>
                                            <div class="control">
                                                <input class="input" type="text" v-model="booking.billing_profile.email" required  >  
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field ">
                                        <button class="button is-success" type="submit" :disabled="working===true" >Re-Send Client Invoice</button>
                                    </div>
                                </form>

                            </div>
                            <div class="column">
                                <div class="row">
                                        <div class="field">
                                            <label class="label">Download Invoice / Timesheet</label>
                                            <div class="control">
                                                <a :href="API_NAME + '/bookings/' + booking.id + '/invoice'" target="_blank"><button class="button is-link " type="button" :disabled="working===true" >Download Invoice PDF</button></a>
                                                <a :href="API_NAME + '/bookings/' + booking.id + '/returned_timesheet'" target="_blank" v-if="booking.timesheet_manual==0"><button class="button is-warning ml-2" type="button" :disabled="working===true" >Download Timesheet PDF</button></a>
                                                <a :href="API_NAME + '/bookings/' + booking.id + '/invoice_and_timesheet'" target="_blank" v-if="booking.timesheet_manual==0"><button class="button is-success ml-2" type="button" :disabled="working===true" >Invoice/Timesheet PDF</button></a>
                                            </div>
                                        </div>
                                    </div>

                            </div>
                        </div>
                    

                    <button class="button is-danger" v-if="booking.reference == '' && booking.client_type_id == 4">Reference for Trust client is not set</button>
                    
                </div>

                
                <div v-if="booking.member_alerts.length > 0">
                    <hr />
                    <h4 class="title is-size-4">Member Alerts</h4>
                    
                    <table class="is-fullwidth is-striped is-size-7 table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Member</th>
                                <th>Interested</th>
                            </tr>
                        </thead>
                        <tr v-for="row in booking.member_alerts" :key="row.id">
                            <td>{{ row.date }}</td>
                            <td>{{ row.forename }} {{ row.surname}}</td>
                            <td style="padding:3px"><span class="tag is-success" v-if="row.interested==1">Yes</span></td>
                        </tr>
                    </table>
                </div>

                <hr />
                <div v-if="booking.logs.length > 0">
                    <h4 class="title is-size-4">Booking Logs</h4>
                    
                    <table class="is-fullwidth is-striped is-size-7 table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>User</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tr v-for="row in booking.logs" :key="'log' + row.id">
                            <td>{{ row.date }}</td>
                            <td>{{ row.forename }} {{ row.surname}}</td>
                            <td>{{ row.text }}</td>
                        </tr>
                    </table>
                </div>

                
            </div>
            <!-- end of check for valid booking -->
          </div>
          
      </div>
  </template>

<script>
    import MemberMixin from '@/mixins/MemberMixin.js';
    import BookingService from '@/services/BookingService.js';
    import UtilityService from '@/services/UtilityService.js';
    import UserService from '@/services/UserService.js';
    
    export default {
      name: 'ViewBooking',
        mixins: [MemberMixin],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    booking:[],
                    working:false,
                    grades: [],
                    wards: [],
                    clients: [],
                    user: [],
                    patients: [],
                    patient_id: '',
                    remove_booking_patient_id: ''
                    
            }
        },
        methods: {
            async getBooking() {
                this.loading = true;

                    BookingService.getBooking(this.accessToken, this.$route.params.id)
                    .then(
                        (booking => {
                            this.$set(this, "booking", booking);
                            this.loading = false
                        this.loading=false
                        this.getWards()
                        this.getClients()
                       
                        }).bind(this)
                    );
                
            },
            async saveBookingPatient() {
                this.working = true
                var formdata = { 
                    booking_id : this.booking.id,
                    patient_id: this.patient_id
                }; 

                if(this.patient_id == '')
                {
                    alert("Please select a patient to add");
                    this.working = false
                }
                else
                {
                    BookingService.saveBookingPatient(this.accessToken, formdata)
                    .then((response) => {
                        this.working = false
                        this.getBooking();
                        console.log(response)
                        
                        }).catch((error) => {
                            if(error.response.status === 422)
                            {
                                console.log("Error:" + JSON.stringify(error.response.data));
                            }
                            alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                            this.working = false
                    });
                }
            },
            async removeBookingPatient() {
                this.working = true
                var formdata = { 
                    booking_patient_id : this.remove_booking_patient_id,
                    booking_id: this.booking.id
                }; 
                
                if(this.remove_booking_patient_id == '')
                {
                    alert("Please select a patient to remove");
                    this.working = false
                }
                else
                {
                    if(confirm("Are you sure you want to remove this patient from this booking ?"))
                    {

                        BookingService.removeBookingPatient(this.accessToken, formdata)
                        .then((response) => {
                            this.working=false
                            this.getBooking();
                            console.log(response)
                            
                            }).catch((error) => {
                                if(error.response.status === 422)
                                {
                                    console.log("Error:" + JSON.stringify(error.response.data));
                                }
                                alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                                this.working = false
                        });
                    }
                    else {
                        this.working = false
                        return false
                    }
                }
            },
            async getWards() {
                UtilityService.getSimpleApiData(this.accessToken, "wards?client_id=" + this.booking.client_id).then(
                    response => this.wards = response 
                );
            },
            async getClients() {
                UtilityService.getSimpleApiData(this.accessToken, "clients?exclude_risky=1").then(
                    response => this.clients = response 
                );
            },
            async updateWards() {
                this.booking.ward_id = 0
                this.getWards();
            },
            async updateBooking() {
                this.working = true

                if(this.booking.invoice_sent == 1)
                {
                    alert("The invoice has already been sent for this shift. Details have NOT been updated.")
                    this.working = false
                    return false
                }
                else{

                

                    var formdata = { 
                        reference : this.booking.reference,
                        grade_id : this.booking.grade_id,
                        client_id : this.booking.client_id,
                        ward_id : this.booking.ward_id,
                        break_time : this.booking.break_time,
                        start_date : this.booking.start_date,
                        start_time: this.booking.start_time,
                        end_time : this.booking.end_time,
                        
                    }; 

                    BookingService.updateBookingDetails(this.accessToken, this.booking.id, formdata)
                    .then(
                        () => {
                            this.working=false;
                            
                            
                            this.getBooking();
                            alert("Booking Updated")
                        }
                    ).catch((error) => { 
                        
                        if (error.response.status == 409) {
                            alert(error.response.data.error)
                        } 
                        else{
                            alert(error.response.data.message)
                        } 
                        this.working=false;
                        
                    });
                }
                
            },
            async updateSleepingNurse(newValue) {
                this.working = true

                if(this.booking.invoice_sent == 1)
                {
                    alert("The invoice has already been sent for this shift. Sleeping nurse setting has NOT been changed.")
                    this.working = false
                    return false
                }
                else{

                

                    var formdata = { 
                        _method: 'patch',
                       sleeping_nurse: newValue
                        
                    }; 

                    BookingService.updateBooking(this.accessToken, this.booking.id, formdata)
                    .then(
                        () => {
                            this.working=false;
                            
                            this.getBooking();
                            alert("Booking Updated")
                        }
                    ).catch((error) => { 
                        
                        if (error.response.status == 409) {
                            alert(error.response.data.error)
                        } 
                        else{
                            alert(error.response.data.message)
                        } 
                        this.working=false;
                        
                    });
                }
                
            },
            async reconfirmBooking() {
                this.working = true
                UtilityService.postSimpleApiData(this.accessToken, "bookings/" + this.booking.id + "/reconfirm")
                .then(
                    () => {
                        alert("Timesheet email resent")
                        this.working = false
                    }
                ).catch((error) => { 
                    
                    if (error.response.status == 409) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.response.data.message)
                    } 
                    this.working = false
                
                })
            },
            async resendInvoice() {
                this.working=true;
                
                var formdata = { 
                       email : this.booking.billing_profile.email
                        
                }; 

                BookingService.sendInvoice(this.accessToken, this.booking.id, formdata)
                .then(
                    () => {
                        this.working=false;
                        
                        alert("Invoice Email Re-Sent")
                    }
                ).catch((error) => { 
                    
                    if (error.response.status == 409) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.response.data.message)
                    } 
                    this.working=false;
                    
                });
            },
            async getUserSelf() {
                UserService.getUser(this.accessToken, "self")
                .then(
                    (user => {
                        this.$set(this, "user", user);
                        
                    }).bind(this)
                );
                
            },
            async getPatients() {
                UtilityService.getSimpleApiData(this.accessToken, "patients?project_id=1").then(
                    response => this.patients = response 
                );
            },
           
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getBooking();
                this.getGrades();
                this.getPatients();
                this.getUserSelf()
            });
        
        },
        computed: {
            filtered_costs() {
                return this.booking.costs.filter(item => item.duration_mins > 0);
            },
        
            filteredPatients() {
            // Get the IDs of member grades
            const memberPatientIds = this.booking.patients.map(mp => mp.patient_id);

            // Filter grades to exclude any that are in member grades
            return this.patients.filter(patient => !memberPatientIds.includes(patient.id));
            }
        }
        
    }
    </script>